import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo"
import { graphql, Link } from "gatsby";

export default function Home ({ data }) {
    return(
        <Layout>
            <SEO title={data.site.siteMetadata.title} description={data.site.siteMetadata.description} />
            <section className="blog-section">
              <h6 className="blog-posts-count">{`Posts: ${data.allMdx.totalCount}`}</h6>
              {data.allMdx.nodes.map(
                ({ id, excerpt, frontmatter, fields }) => (
                  <div key={id}>
                    <Link to={fields.slug}>
                      <h4>{frontmatter.title}</h4>     
                    </Link>
                    <p>{frontmatter.date}</p>
                    <p>{excerpt} <Link to={fields.slug}>Read More...</Link></p>
                  </div>
                )
              )}
            </section>
        </Layout>
    )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        url
        copyright
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
        }
        fields {
          slug
        }
      }
      totalCount
    }
  }
`;